import axios from 'axios';
import { combineReducers, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CancelAt, CancellationInitiator, V1Subscription } from '@wix/ambassador-subscriptions-api/types';
import { Thunk, ThunkApiConfig } from '../../types/thunk-extra';
import { detailsFixture, subscriptionsFixture } from '../../fixtures';
import { getCancelConfirmModalSubscriptionId, getSubscriptionById } from './selectors';

type LanguageState = string;
const languageSlice = createSlice({
  name: 'language',
  initialState: 'en' as LanguageState,
  reducers: {
    setLanguage: (_, action) => action.payload,
  },
});

type RegionalSettingsState = string;
const regionalSettingsSlice = createSlice({
  name: 'regionalSettings',
  initialState: 'en' as RegionalSettingsState,
  reducers: {
    setRegionalSettings: (_, action) => action.payload,
  },
});

type UserState = any;
const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {
    setUser: (_, action) => action.payload,
  },
});

export const { setLanguage } = languageSlice.actions;
export const { setRegionalSettings } = regionalSettingsSlice.actions;
export const { setUser } = userSlice.actions;

type AccordionState = any[];
const accordionSlice = createSlice({
  name: 'accordion',
  initialState: [] as AccordionState,
  reducers: {
    open: (state, action) => [...state, action.payload],
    close: (state, action) => state.filter((id) => id !== action.payload),
  },
});

type CancelConfirmModalState = { subscriptionId: null | string; isOpen: boolean };
const cancelConfirmModalSlice = createSlice({
  name: 'cancelConfirmModal',
  initialState: { subscriptionId: null, isOpen: false } as CancelConfirmModalState,
  reducers: {
    open: (state, action) => ({ subscriptionId: action.payload, isOpen: true }),
    close: () => ({ subscriptionId: null, isOpen: false }),
  },
});

export const cancelSubscription = createAsyncThunk<V1Subscription, string, ThunkApiConfig>(
  'subscriptions/cancel',
  async (subscriptionId, { extra: { subscriptionService }, getState }) => {
    const state = getState();
    const { user } = state;
    const subscription = getSubscriptionById(state, subscriptionId);
    const response = await subscriptionService({
      Authorization: user.instance,
    }).requestCancellation({
      id: subscriptionId,
      cancellationInitiator: CancellationInitiator.MEMBER,
      cancelAt: subscription.recurring ? CancelAt.NEXT_PAYMENT_DATE : CancelAt.IMMEDIATELY,
    });

    const subscriptionResponse = await subscriptionService({
      Authorization: user.instance,
    }).getSubscription({
      id: response.id,
    });

    return subscriptionResponse.subscription!;
  },
);

export const openCancelConfirmModal = createAsyncThunk<void, string, ThunkApiConfig>(
  'subscriptions/openCancelConfirmModal',
  async (subscriptionId, { extra: { biLogger }, getState, dispatch }) => {
    const state = getState();
    const subscription = getSubscriptionById(state, subscriptionId);

    biLogger.mySubscriptionsCancelSubscriptionConfirmed({
      action: 'cancel',
      subscriptionId,
      originEntityId: subscription.originEntityId,
      appId: subscription.wixAppId,
    });

    dispatch(cancelConfirmModalSlice.actions.open(subscriptionId));
  },
);

export const closeCancelConfirmModal = cancelConfirmModalSlice.actions.close;
export const confirmCancel = createAsyncThunk<void, void, ThunkApiConfig>(
  'cancelConfirmModal/confirmCancel',
  async (arg, { extra: { biLogger }, dispatch, getState }) => {
    const state = getState();
    const subscriptionId = getCancelConfirmModalSubscriptionId(state) as string;
    const subscription = getSubscriptionById(state, subscriptionId);

    biLogger.mySubscriptionsCancelSubscriptionConfirmed({
      action: 'cancel-confirm',
      subscriptionId,
      originEntityId: subscription.originEntityId,
      appId: subscription.wixAppId,
    });

    dispatch(cancelSubscription(subscriptionId));
    dispatch(closeCancelConfirmModal());
  },
);

export const fetchAllSubscriptions = createAsyncThunk<V1Subscription[] | undefined, void, ThunkApiConfig>(
  'subscriptions/fetchAll',
  async (_, { extra, getState }) => {
    const { user } = getState();
    if (!user?.loggedIn) {
      return;
    }

    const { subscriptions } = await extra.subscriptionService({ Authorization: user.instance }).listSubscriptions({
      subscriberIds: [user.id],
    });

    return subscriptions;
  },
);

type SubscriptionsState = { entities: any[]; loading: string };
export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: { entities: [], loading: 'idle' } as SubscriptionsState,
  reducers: {
    mockSubscriptions: (state, action) => ({
      ...state,
      entities: action.payload,
    }),
  },
  extraReducers: {
    [fetchAllSubscriptions.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    [fetchAllSubscriptions.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.entities.push(...action.payload);
      }
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
    [fetchAllSubscriptions.rejected.type]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
    [cancelSubscription.fulfilled.type]: (state, action) => {
      const idx = state.entities.findIndex((s) => s.id === action.payload.id);
      if (idx > -1) {
        state.entities[idx] = action.payload;
      }
    },
  },
});

const fetchSubscriptionDetailsById = createAsyncThunk<any, string, ThunkApiConfig>(
  'subscriptionDetails/fetchById',
  async (subscriptionId, { extra, getState }) => {
    const { baseUrl } = extra;
    const { user } = getState();

    if (!user?.loggedIn) {
      return;
    }

    const response = await axios.get(
      `${baseUrl}/_serverless/subscriptions-api/subscriptions/${subscriptionId}/details`,
      { headers: { Authorization: user.instance } },
    );
    return response.data;
  },
);

export const openDetails =
  (subscriptionId: string): Thunk =>
  (dispatch, getState, { biLogger }) => {
    const subscription = getSubscriptionById(getState(), subscriptionId);
    dispatch(accordionSlice.actions.open(subscriptionId));

    if (!subscriptionId.includes('mock')) {
      biLogger.mySubscriptionsShowDetails({
        subscriptionId,
        subscriptionStatus: subscription.subscriptionStatus,
      });
      dispatch(fetchSubscriptionDetailsById(subscriptionId));
    }
  };

export const mockSubscriptions = (): Thunk => (dispatch) => {
  dispatch(subscriptionsSlice.actions.mockSubscriptions(subscriptionsFixture));
  dispatch(detailsSlice.actions.mockDetails({ ...detailsFixture, id: subscriptionsFixture[0].id }));
  dispatch(openDetails(subscriptionsFixture[0].id!));
};

export const closeDetails = accordionSlice.actions.close;

type DetailsState = { entities: any; loading: any[] };
const detailsSlice = createSlice({
  name: 'details',
  initialState: { entities: {}, loading: [] } as DetailsState,
  reducers: {
    mockDetails: (state, action) => ({
      ...state,
      entities: { ...state.entities, [action.payload.id]: action.payload },
    }),
  },
  extraReducers: {
    [accordionSlice.actions.open.type]: (state, action) => {
      state.loading.push(action.payload);
    },
    [fetchSubscriptionDetailsById.fulfilled.type]: (state, action) => {
      const subscriptionId = action.meta.arg;
      state.entities[subscriptionId] = action.payload;
      state.loading = state.loading.filter((id) => id !== subscriptionId);
    },
    [fetchSubscriptionDetailsById.rejected.type]: (state, action) => {
      state.loading = state.loading.filter((id) => id !== action.meta.arg);
    },
  },
});

export interface RootState {
  cancelConfirmModal: CancelConfirmModalState;
  language: LanguageState;
  regionalSettings: RegionalSettingsState;
  accordion: AccordionState;
  subscriptions: SubscriptionsState;
  details: DetailsState;
  user: UserState;
}

const rootReducer = combineReducers({
  cancelConfirmModal: cancelConfirmModalSlice.reducer,
  language: languageSlice.reducer,
  regionalSettings: regionalSettingsSlice.reducer,
  accordion: accordionSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  details: detailsSlice.reducer,
  user: userSlice.reducer,
});

export default rootReducer;
